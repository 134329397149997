import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './styles-jss';
import { Grid, Hidden } from '@material-ui/core';
import Footer from './footer';
function Decoration(props) {
  const {
    classes,
    mode,
    gradient,
    decoration,
    bgPosition,
    horizontalMenu
  } = props;
  const getBgPosition = position => {
    if (position === 'header') {
      return classes.headerBg;
    }
    if (position === 'half') {
      return classes.halfBg;
    }
    return classes.fullBg;
  };
  const renderDecor = (type, position) => {
    const mergeDeco = type + '-' + position;
    if (mergeDeco === 'dark-half') {
      return (
        <img src={'/images/decoration_dark.svg'} alt="decoration" className={classes.decorationWave} />
      );
    }
    if (mergeDeco === 'dark-full') {
      return (
        <img src={'/images/petal_bg.svg'} alt="decoration" className={classes.decorationPetal} />
      );
    }
    if (mergeDeco === 'light-half') {
      return (
        <img src={'/images/decoration_light.svg'} alt="decoration" className={classes.decorationWave} />
      );
    }
    if (mergeDeco === 'light-full') {
      return (
        <img src={'/images/petal_bg.svg'} alt="decoration" className={classes.decorationPetal} />
      );
    }
    return false;
  };
  return (
    <div>
      <div className={classes.bgWrap}>
        <div
          className={classNames(
            classes.bgbar,
            classes.gradientBg,
            classes.halfBg,
          )}
        >
          <img src={'/images/decoration_light.svg'} alt="decoration" className={classes.decorationWave} />
          <Footer />
        </div>
      </div>
    </div>

  );
}


export default (withStyles(styles)(Decoration));
