import React, { useState, useEffect } from "react";
import {
  Grid, TextField, Card, Button, CardContent, CardActions,
  Typography, Slide, Slider, FormControl, InputLabel, Dialog
} from "@material-ui/core";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import NotFound from "./404";
import { API } from "../api/index";
import { DataService } from "../api/dataService";
import Decoration from "../components/layout/decoration";
import styles from "./styles-jss";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as yup from 'yup'
import { useFormik } from 'formik'
import LoadingButton from '../components/ui/loadingButton'
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from '../components/layout/footer';
import { Hidden } from "@material-ui/core";
import Bugsnag from '@bugsnag/js'

const Pay = (props) => {
  const { classes } = props;
  let { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    GetData();
  }, [token]);

  async function GetData() {
    var res = await DataService.get(`getLinks?token=${token}`);
    if (res.data.success) {
      setLoading(false);
      setData(res.data.data);
    } else {
      window.location.replace("https://www.wiwi.co.il");
    }
  }

  if (loading) {
    return (
      <div>
        <Dialog PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }} style={{ color: "#fff", zIndex: 99999, }} open={true}>
          <img height="150" src='/images/loader.gif' />
        </Dialog>
      </div>)
  }

  return (
    <div>
      <Grid container className={classes.container} justifyContent="center" alignContent="center">
        {/* <Grid item xs={12} md={3} lg={4}></Grid> */}
        <Grid item xs={12} style={{ maxWidth: '650px' }}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <br />
              <div className={classes.logoContainer}>
                <img width="100%" src="/images/wiwi-logo.svg" />
              </div>
              <br />
              <Typography variant="h4" className={classes.title} color="primary" align="center">{data?.event}</Typography>

            </CardContent>
            <CardActions style={{ justifyContent: 'center', flexDirection: 'column' }}>
              <Grid container spacing={2} style={{marginTop: 20, marginBottom: 20, width: '100%' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LoadingButton fullWidth onClick={() => window.location.replace(data?.giftLink)}
                    classes={{ root: classNames(classes.gradientBtn, classes.margin) }} size='large'>להענקת מתנה</LoadingButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LoadingButton onClick={() => window.location.replace(data?.wazeLink)} fullWidth
                    classes={{ root: classNames(classes.gradientBtn, classes.margin) }}
                  >לניווט באמצעות Waze</LoadingButton >
                </Grid>
              </Grid>
            </CardActions>

          </Card>
          <Decoration
            mode={'light'}
            gradient={true}
            decoration={true}
            bgPosition={'half'}
            horizontalMenu={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Pay);
