import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import NotFound from "./pages/404";
import Success from "./pages/success";
import Pay from "./pages/index";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  appVersion: '1.1',
  apiKey: 'db845a739d6a9d88400bcf714b0ff1b8',
  plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createTheme({
  direction: 'rtl',
  typography: {
    allVariants: { fontFamily: "'Heebo', sans-serif" },
  },
  palette: {
    primary: {
      light: '#FCE4EC',
      main: '#EC407A',
      dark: '#D81B60',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFF8E1',
      main: '#FFA000',
      dark: '#FF6F00',
      contrastText: '#fff',
    },
  },
});

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <Switch>

              <Route exact path="/success/:token?/" component={Success} />
              <Route exact path="/:token?" component={Pay} />
              {/* <Route component={NotFound} /> */}
            </Switch>
          </Router>
        </MuiThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  );
}

export default App;


const ErrorView = () =>
  <div>
    <p>An Error Occured</p>
  </div>

//ASXpWyGqvAqw4Kd4kao7DGiqoStl6fjf3QquACYxsdRvdAgmY0or3qcv4Hv1