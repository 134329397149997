const styles = theme => ({
  container: {

    // [theme.breakpoints.down('xs')]: {
    //   backgroundColor: '#fafafa'
    // }
  },
  gradientBtn: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '44px',
    // padding: '0 61px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 200,
    },
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .30)',
    "&:focus": {
      outline: '1px dotted'
    }
  },
  backButton: {
    "&:focus": {
      outline: 'none'
    }
  },
  card: {
    margin: '40px',
    padding: '50px',
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
      margin: '25px',
      //marginBottom: '25px'
    }

  },
  margin: {
    //  margin: theme.spacing(1),
  },
  logoContainer: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    display: 'flex',
    alignItems: 'center'

  },
  inputWrapper: {
    height: '20px',
    borderBottom: 'gray 1px solid',
    //  marginLeft:'2px',
    //  marginRight:'5px'    
  },
  inputLabel: {
    width: '100%',
    textAlign: 'left',
    fontSize: '12px',
    color: 'gray'
  },
  inputErrorLabel: {
    width: '100%',
    textAlign: 'left',
    fontSize: '12px',
    color: 'red'
  },
  globalErrorLabel: {
    width: '100%',
    textAlign: 'center',
    fontSize: '16px',
    color: 'red'
  },
  heartShape: {
    width: '24px',
    height: '24px',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    clipPath: 'path("M12 4.419c-2.826-5.695-11.999-4.064-11.999 3.27 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-7.327-9.17-8.972-12-3.27z")',
    transform: 'scale(5)'
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
    },
  },
  bitSuccessMessage: {
    color: '#e72285',
    animationName: '$blinker',
    animationDuration: '0.7s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
});

export default styles;
